import { Error } from '@material-ui/icons';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [,setCookie] = useCookies();
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post('/login', { email, password })
        .then(({ data }) => {
            setCookie('ntemAccess', data.token, { 
                path: '/',
                maxAge: 10000000*3  // epxires in approximately a year
            });

            // if there's a channel property then the user is a rider
            if(data.channel) {
                setCookie('channel', data.channel, { 
                    path: '/',
                    maxAge: 10000000*3 // expires in approximately a year
                });
                history.push('/rider')
            } else {
                history.push('/');
            }

            setLoading(false);
        })
        .catch((err) => {
            if(err.response) {
                setError(err.response.data);
            } else {
                setError('network too slow or inactive');
            }
            setLoading(false);
        })
    }

    return (
        <form className='login' method='POST' onSubmit={handleSubmit}>
            <div className='con'>
                <div>Email</div>
                <input 
                    className='normal-input'
                    type='email'
                    placeholder='kwame@example.com'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </div>

            <div className='con'>
                <div>Password</div>
                <input
                    className='normal-input'
                    type='password'
                    placeholder='password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </div>

            {
                error&& <div className='error'><Error /> { error }</div>
            }
            
            <button type='submit'>
                {
                    loading? 
                    <Loader
                        type='ThreeDots'
                        color='white'
                        height={10}
                        width={30}/> :
                    'Login'
                }
            </button>

            <p className='small-info'>Not a user? Click on the Register tab to get registered.</p>
        </form>
    )
}
