import { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Error } from '@material-ui/icons';
import Loader from 'react-loader-spinner';

import RiderTrackingItem from './RiderTrackingItem';
import RiderMap from './RiderMap/RiderMap';
import notifyRider from '../../utils/notifyRider';
let interval;

export default function Rider() {
    const [delivery, setDelivery] = useState('');
    const [user, setUser] = useState('');
    const [rider, setRider] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [{ntemAccess}] = useCookies(['ntemAccess']);


    useEffect(() => {
        document.title = 'Ntem Deliveries | Track Order';
        interval = setInterval(() => {
            update();
        }, 4000);
    }, []);

    const update = (e) => {
        axios({
            method: 'get',
            headers: {
                "x-access-token": ntemAccess
            },
            url: `/rider-check-delivery`
        })
        .then(({ data }) => {
            setDelivery(data.delivery);
            setRider(data.rider);
            setUser(data.user);
            notifyRider();
            clearInterval(interval);
        })
        .catch((err) => {
            console.log(err);
        })
    }


    const handleComplete = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post(`/complete`, { trackingId: delivery.trackingId })
        .then(() => {
            setLoading(false);
            setDelivery('');
            setRider('');
            setUser('');
            interval = setInterval(() => {
                update();
            }, 4000);
        })
        .catch((err) => {
            if(err.response) {
                setError(err.response.data);
            } else {
                setError('network too slow or inactive');
            }
            setLoading(false);
        })
    }

    return (
        <div className='track container'>
            {
                delivery?
                <div>
                    <RiderMap data={delivery} rider={rider} />
                    <RiderTrackingItem data={delivery} user={user}/>

                    {
                        error&& <div className='error'><Error /> { error }</div>
                    }

                    <button onClick={handleComplete} className='complete-btn'>
                        {
                            loading?
                            <Loader
                                type='ThreeDots'
                                color='white'
                                height={13}
                                width={25}/> : 
                            'Complete'
                        }
                    </button>
                </div> :
                <div className='empty-box'>You have no deliveries to perform</div>
            }

        </div>
    )
}