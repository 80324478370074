function createNotification(title, text, icon) {
    new Notification(title, {
        body: text,
        icon
    });
}

export default function notifyRider() {
    if(Notification.permission === "granted") {
        createNotification('Ntem Deliveries', 'You have a delivery to make');
    }
    else {
        Notification.requestPermission(permission => {
          if(permission === 'granted') {
            createNotification('Ntem Deliveries', 'You have a delivery to make');
        }
        });
    }
}