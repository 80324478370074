import { Link, useHistory } from 'react-router-dom';
import { BorderColorRounded } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import logo from '../../images/logo.png';

import './Header.css';

export default function Header() {
    const history = useHistory();

    return (
        <div className='header container'>
            <div className='brand'>
                <a href='https://ntemdeliveries.com'><img src={logo} alt='logo' /></a>
            </div>

            <nav>
                <a href='https://ntemdeliveries.com'>HOME</a>
                <a href='https://ntemdeliveries.com/services'>SERVICES</a>
                <button className='book' onClick={() => history.push('/')}>BOOK A DELIVERY</button>
                <Link to='/' className='book-icon'><IconButton><BorderColorRounded/></IconButton> </Link>
            </nav>
        </div>
    );
}