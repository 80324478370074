import CurrencyFormat from 'react-currency-format';

export default function TrackingItem({ data, rider }) {
    return (
        <div className='item'>
            <div className='row'>
            <CurrencyFormat 
                className='cost'
                value={data.cost.toFixed(2)} 
                displayType={'text'} 
                thousandSeparator={true} 
                prefix={'Ghc '} />
                <span className={`${data.status === 'pending' || data.status === 'assigned'? 'pending': 'completed'}`}>{data.status}</span>
            </div>

            {
                rider&&
                <div className='row'>
                    <div>Rider: </div>
                    <div>{ rider.name }</div>
                </div>
            }

            
            <div className='row'>
                <div>Category: </div>
                <div>{ data.category }</div>
            </div>

            <div className='row'>
                <div>Vehicle: </div>
                <div>{ data.vehicle }</div>
            </div>

            <div className='row'>
                <div>Weight:</div>
                <div>{ data.weight }Kg</div>
            </div>

            <div className='row'>
                <div>From:</div>
                <div>{ data.from.address }</div>
            </div>

            <div className='row'>
                <div>To:</div>
                <div>
                    { 
                        data.to.map(to => <div className='to-item'>
                            {
                                data.to.length > 1&&
                                <span>{data.to.indexOf(to)+1}</span>
                            }
                            { to.address }
                            </div>)
                    }
                </div>
            </div>

            {
                data.insurance?
                <div className='row'>
                    <div>Insurance:</div>
                    <CurrencyFormat 
                        value={data.insurance} 
                        displayType={'text'} 
                        thousandSeparator={true} 
                        prefix={'Ghc '} />
                </div> : ''
            }

            {
                data.schedule !== 'now'&&
                <div className='row'>
                <div>Scheduled to:</div>
                <div>{ data.schedule }</div>
            </div>
            }
        </div>
    )
}
