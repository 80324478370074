import { useState } from 'react';
import { useRouteMatch, Switch, Route, Link } from 'react-router-dom';
import { BorderColorRounded, PlaceRounded} from '@material-ui/icons';

import BookDelivery from '../components/BookDelivery/BookDelivery';
import TrackDelivery from '../components/TrackDelivery/TrackDelivery';
import { MenuItem } from '@material-ui/core';


export default function Home() {
    const { path, url } = useRouteMatch();
    const [currentTab, setCurrentTab] = useState(1);

    return (
        <div className='home container'>
            <div className='tabs'>
                <MenuItem className={currentTab === 1&& 'active'} onClick={() => setCurrentTab(1)}><Link to={url}><BorderColorRounded /><span>Book Delivery</span></Link></MenuItem>
                <MenuItem className={currentTab === 2&& 'active'} onClick={() => setCurrentTab(2)}><Link to={`${url}track`}><PlaceRounded /> <span>Track Order</span></Link></MenuItem>
            </div>
            <div>
                <Switch>
                    <Route exact path={path} component={BookDelivery}/>
                    <Route exact path={`${path}track`} component={TrackDelivery}/>
                </Switch>
            </div>
        </div>
    )
}
