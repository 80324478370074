import { useState, useEffect } from 'react';
import { Alarm, Motorcycle, TimeToLeave, Error, Check, DirectionsRun, Add } from '@material-ui/icons';
import Loader from 'react-loader-spinner';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Clipboard from 'react-clipboard.js';

import './BookDelivery.css';
import Category from './Category/Category';
import Location from './Location/Location';
import calculateTotal from '../../utils/calculateTotal';

export default function BookDelivery() {
    const [isScheduled, setIsScheduled] = useState(false);
    const [vehicle, setVehicle] = useState(0); // 0 for motorbike 1 for car ( set to motorbike by default)
    const [weight, setWeight] = useState(1);
    const [category, setCategory] = useState('');
    const [total, setTotal] = useState('10.00');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // if the user is not registered
    const [phone, setPhone] = useState('');

    const handlePhone = (e) => {
        if(e.target.value.length <= 10) {
            setPhone(e.target.value);
        }
    }

    const [locations, setLocations] = useState([
        {
          address: '',
          phone: '',
          details: ''
        },
        {
            address: '',
            phone: '',
            details: ''
          }
    ])

    const handleAdd = () => {
        setLocations([...locations, {
          address: '',
          phone: '',
          details: ''
        }])
    }

    const handleRemove = (index) => {
        const newLocations = [...locations];
        newLocations.splice(index, 1);
        setLocations(newLocations);
    }

    // Scheduled time
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');

    const [trackingId, setTrackingId] = useState('');

    const [{ntemAccess}] = useCookies(['ntemAccess']);

    const updateTotal = async () => {
        const [, ...rest] = locations; // rest is the array minus the first element

        const total = await calculateTotal({
            origin: locations[0].address, 
            destinations: rest.map((l) => {
                    return l.address;
                }), 
            vehicle
        });

        setTotal(total);
    }

    useEffect(() => {
        document.title = 'Ntem Deliveries | Book Delivery';
    })

    useEffect(() => {
        updateTotal();
    }, [vehicle]);

    useEffect(() => {
        if(weight > 30) setVehicle(1);
    }, [weight, vehicle]);


    const handleSubmit = () => {
        if(!ntemAccess) {
            if(!phone) return setError("'phone' can't be empty");
        }
        const [, ...rest] = locations
        setLoading(true);
        axios({ 
            method: 'post',
            url: '/delivery',
            headers: ntemAccess&& { "x-access-token": ntemAccess },
            data: { 
                category,
                vehicle: vehicle === 0? 'Motorbike' : 'Car',
                weight,
                cost: total,
                schedule: isScheduled? `${date}, ${time}` : 'now',
                from: locations[0],
                to: rest,
                phone: !ntemAccess? phone : ''
            }
        })
        .then(({ data }) => {
            setSuccess(data.msg);
            setTrackingId(data.trackingId);
            setError('');
            setLoading(false);
        })
        .catch((err) => {
            if(err.response) {
                setError(err.response.data);
            } else {
                setError('network too slow or inactive');
            }
            setLoading(false);
        })
    }

    const handleWeight = ({ target }) => {
        const { value } = target;
        if(value <= 300 && value >= 0) {
            setWeight(value);
        } else {
            setWeight(300);
        }
    }

    return (
        <div className='book'>

            <p><b>BOOK A DELIVERY</b></p>

            <div className='toggle row flex-start'>
                <button className={!isScheduled&& 'active'} onClick={() => setIsScheduled(false)}><DirectionsRun /> Delivery Now</button>
                <button className={isScheduled&& 'active'} onClick={() => setIsScheduled(true)}><Alarm /> Scheduled Delivery</button>
            </div>

            {
                isScheduled&& 
                <div className='schedule row flex-start'>
                    <div>
                        <label>Date:</label>
                        <input
                            type='date'
                            value={date}
                            onChange={e => setDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <lable>Time:</lable>
                        <input 
                            type='time'
                            value={time}
                            onChange={e => setTime(e.target.value)}
                        />
                    </div>
                </div>
            }

            <p className='small-info'>
                {
                    isScheduled? "Courier will arrive within your scheduled time" : "Courier will arrive as soon as possible"
                }
            </p>

            <div className='row flex-start group'>
                <div className='toggle vehicle'>
                    <p><b>Vehicle Type</b></p>
                    <div className='row'>
                    <button className={vehicle === 0&& 'active'} onClick={() => setVehicle(0)}><Motorcycle /> Motorbike</button>
                    <button className={vehicle === 1&& 'active'} onClick={() => setVehicle(1)}><TimeToLeave /> Car</button>
                    </div>
                </div>
                <div className='weight'>
                    <p><b>Item Weight</b></p>
                    <div className='con'>
                        <input 
                            type='number'
                            value={weight}
                            onChange={handleWeight}
                        />Kg
                    </div>
                </div>
            </div>

            {
                !ntemAccess&&
                <div className='phone'>
                    <p><b>Phone</b></p>
                    <div className='input-con'>
                        <span>+233</span>
                        <input 
                            value={phone}
                            type='tel'
                            onChange={handlePhone}
                            placeholder='Your phone number'
                        />
                    </div>
                </div>
            }

            
            <div>
                <div className='locations'>
                    {
                        locations.map(l => (
                            <Location 
                                index={locations.indexOf(l)} 
                                locations={locations} 
                                setLocations={setLocations}
                                updateTotal={updateTotal}
                                handleRemove={handleRemove} />
                        ))
                    }
                </div>

                <button onClick={handleAdd} className='add-address'><Add /> Add Address</button>
            </div>
            
           <Category category={category} setCategory={setCategory} />

            <div className='box'>
                    {
                        success&& <div className='success'><Check /> { success }</div>
                    }

                    {
                        error&& <div className='error'><Error /> { error }</div>
                    }

                    {
                        trackingId&& 
                        <div className='tracking-id'>
                            Tracking ID: <Clipboard data-clipboard-text={trackingId} onSuccess={() => alert('Trackind Id copied')}>
                                    { trackingId }
                                </Clipboard> (click to copy)
                        </div>
                    }

                    <h3>
                        Total: <CurrencyFormat 
                                    value={total} 
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Ghc '} />
                    </h3>

                    <button type='submit' class='total-btn' onClick={handleSubmit}>
                        {
                            loading? 
                                <Loader 
                                    type='ThreeDots'
                                    color='white'
                                    height={10}
                                    width={30}/>:
                            'Place Order'
                        }
                    </button>
                    <p>To check updates about the order, please click the <i>Track Order</i> tab</p>
            </div>

            <div className='floating-total'>
                Total: <CurrencyFormat 
                            value={total} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            prefix={'Ghc '} />
            </div>
        </div>
    )
}
