import { useState, useEffect } from 'react';
import "./Location.css";
import mapsLogo from '../../../images/maps_logo.jpg';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Tooltip, IconButton } from '@material-ui/core';
import PoweredByGoogleImg from "../../../images/powered.png";
import Map from './Map';
import { PlaceRounded, Close } from '@material-ui/icons';

export default function Location({ index, locations, setLocations, updateTotal, handleRemove }) {
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [details, setDetails] = useState('');

    const [showMap, setShowMap] = useState(false);
    
    useEffect(() => {
        const newLocations = locations;
 
        newLocations[index].address = address;
        newLocations[index].phone = phone;
        newLocations[index].details = details;
         
         setLocations(newLocations);
     }, [address, phone, details]);

     useEffect(() => {
        if(index === (locations.length - 1)) {
            updateTotal();
        }
     }, [address, updateTotal])

    const handlePhone = (e) => {
        if(e.target.value.length <= 10) {
            setPhone(e.target.value);
        }
    }


    return (
        <div className='location box'>
            <div className='row'>
                <div className='label'>
                    <span>{ index + 1 }</span>
                    <span>{index === 0? 'From' : 'To'}</span>
                </div>

                {
                    index > 1&&
                    <IconButton onClick={() => handleRemove(index)}>
                        <Close />
                    </IconButton>
                }
            </div>

            <div className='input-con address'>
                <Tooltip title={<div className='tooltip'>click to select location from map</div>}>  
                    <img onClick={() => setShowMap(true)} src={mapsLogo} alt=''/>
                </Tooltip>
                <div className='input'>
                    <PlacesAutocomplete
                        value={address}
                        onChange={a => setAddress(a)}
                        onSelect={(a) => setAddress(a)}>
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                        <div>
                            <input
                            {...getInputProps({
                                placeholder: `Please input your ${locations[index].from? 'pick up' : 'drop off'} address here`,
                                className: 'location-search-input',
                            })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                        })}
                                    >
                                        <PlaceRounded />
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                                {
                                    suggestions.length > 0 && 
                                    <div className="powered-by-google">
                                        <img src={PoweredByGoogleImg} alt="powered by google" />
                                    </div>
                                }
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                </div>

            </div>

            <div className='input-con phone'>
                <span>+233</span>
                <input 
                    type='tel'
                    value={phone}
                    onChange={handlePhone}
                    placeholder='Contact person'
                />
            </div>

            <div className='input-con details'>
                <input 
                    type='text'
                    placeholder='Address Details: Appartment name, Unit Number, Landmark, etc'
                    value={details}
                    onChange={e => setDetails(e.target.value)}
                />
            </div>

            <Map 
                show={showMap} 
                setShow={setShowMap}
                setAddress={setAddress} 
            />
        </div>
    )
}