import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import './LoginOrRegister.css';
import Login from '../components/Login';
import Register from '../components/Register';

export default function LoginOrRegister() {
    const [currentTab, setCurrentTab] = useState(0);
    const history = useHistory();
    const [cookies] = useCookies();

    useEffect(() => {
        if(cookies.channel) {
            history.push('/rider');
        } else if(cookies.ntemAccess) {
            history.push('/');
        }

        document.title = 'Ntem Deliveries | Login or Register';
    });


    return (
        <div className='login-or-register container'>
            <div className='tabs'>
                <button className={currentTab === 0&& 'active'} onClick={() => setCurrentTab(0)}>LOGIN</button>
                <button className={currentTab === 1&& 'active'} onClick={() => setCurrentTab(1)}>REGISTER</button>
            </div>
            {
                currentTab === 0?
                <Login /> :
                <Register />
            }
        </div>
    )
}
