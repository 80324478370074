import './Category.css';

export default function Category({ category, setCategory }) {
    return (
        <div className='category box'>
            <h3>Category</h3>
            <input 
                className='normal-input'
                type='text' 
                placeholder='What are you delivering? (Please type if category not found here)'
                value={category}
                onChange={e => setCategory(e.target.value)}
            />
            <div className='row flex-start'>
                <button className='item' onClick={e => setCategory('Document')}>Document</button>
                <button className='item' onClick={e => setCategory('Food & Beverages')}>Food & Beverages</button>
                <button className='item' onClick={e => setCategory('Gift')}>Gift</button>
                <button className='item' onClick={e => setCategory('Groceries')}>Groceries</button>
                <button className='item' onClick={e => setCategory('Flower')}>Flower</button>
                <button className='item' onClick={e => setCategory('Cake')}>Cake</button>
                <button className='item' onClick={e => setCategory('Parcel')}>Parcel</button>
            </div>
    </div>
    )
}
