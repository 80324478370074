import { useCookies } from 'react-cookie';
import { useHistory, Link } from 'react-router-dom';
import { CallOutlined, ExitToAppRounded, Facebook, Instagram, LinkedIn, PersonAddOutlined, PersonOutline, Twitter, WhatsApp } from '@material-ui/icons';

import './Header.css';

const TopHeader = () => {
    const [{ntemAccess}, , removeNtemAccess] = useCookies(['ntemAccess']);
    const [{channel}, , removeChannel] = useCookies(['channel']);
    const history = useHistory();

    const handleLogout = () => {
        removeNtemAccess('ntemAccess');
        removeChannel('channel');
        history.push('/login-or-register');
    }

    return (
        <div className='top container row'>
        <div className='social row'>
            <a href='https://www.facebook.com/speed.ntemdeliveries'><Facebook /></a>
            <a href='#'><Twitter /></a>
            <a href='https://www.instagram.com/speed.ntemdeliveries'><Instagram /></a>
            <a href='#'><LinkedIn /></a>
            <a href='#'><WhatsApp /></a>
        </div>

        <div className='row flex-start right'>
            {
                !channel&&
                <span><Link to='/client'><PersonAddOutlined />BECOME A CLIENT</Link></span>
            }
            {
                ntemAccess?
                <span><Link onClick={handleLogout}><ExitToAppRounded /> LOGOUT</Link></span> :
                <span><Link to='/login-or-register'><PersonOutline />LOGIN / REGISTER</Link></span> 
            }

            {
                channel&&
                <span><Link to='/rider'><PersonOutline />RIDER</Link></span> 
            }
            {
                !channel&&
                <span><CallOutlined /><a href='tel:+233208602273'>+233 (0) 208602273</a></span>
            }
        </div>
    </div>
    )
}

export default TopHeader;