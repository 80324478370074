import { useEffect, useState, useRef } from 'react';
import './RiderMap';
import { GoogleMap, DirectionsRenderer, Marker, InfoWindow } from '@react-google-maps/api';
import getLatLng from '../../../utils/getLatLng';
import getCurrentLocation from '../../../utils/getCurrentLocation';
import io from 'socket.io-client';

const ENDPOINT = 'https://ntem-backend.herokuapp.com';
let socket;

const RiderMap = ({ data, rider }) => {
    const [directions, setDirections] = useState(null);
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();

    const markerRef = useRef();
    
    useEffect(() => {
        async function sendLocation() {
            socket = io(ENDPOINT);
            socket.emit('join', { channel: rider.channel });

            socket.emit('sendLocation', { location: await getCurrentLocation() });
        }
        sendLocation();
        
        if (navigator.geolocation) {
            // runs everytime the rider changes position
            navigator.geolocation.watchPosition(function(position) {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                
                setLat(lat);
                setLng(lng);
                socket.emit('sendLocation', { location: { lat, lng } });
            });
        }
    }, []);

    const getDirections = async () => {
        const directionsService = new window.google.maps.DirectionsService();

        const origin = await getLatLng(data.from.address);
        const destination = await getLatLng(data.to[data.to.length -1].address);

        // we don't need the last address in the waypoints
        data.to.pop();

        const waypoints = data.to.map(({ address }) => {
            return {
                location: address
            }
        })

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
                waypoints,
            
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
   }

   useEffect(() => {
    getDirections();
   }, []);

    return (
        <div className='rider-map'>
            <GoogleMap 
                mapContainerStyle={{
                    flex: 1, 
                    height: 500, 
                    marginBottom: 30,
                    borderRadius: 7,
                    boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.06)'
                }}
                center={{lat, lng}}
            >
                <DirectionsRenderer directions={directions} />
                <Marker position={{lat, lng}} title={rider.name} animation={window.google.maps.Animation.BOUNCE}/>
            </GoogleMap>
        </div>
    )
}

export default RiderMap;