import axios from 'axios';

const getPrices = (vehicle) => {
    return axios.get(`/prices/${vehicle}`);
}

const getDistanceAndTime = (origin, destination) => {
    return new Promise((resolve , reject) => {
        const service = new window.google.maps.DistanceMatrixService();

        service.getDistanceMatrix({ 
            origins: [origin], 
            destinations: [destination], 
            travelMode: 'DRIVING'},
            (res) => {
                try {
                    const distance = Math.round(res.rows[0].elements[0].distance.value/1000);
                    const duration = Math.round(res.rows[0].elements[0].duration.value/60);
                    resolve({ distance, duration });
                } catch(err) {
                    reject("Promise rejected");
                }

            });
        });
}

// calculates the cost between two places
const calculateCost = async (origin, destination, vehicle) => {
    let { distance, duration } = await getDistanceAndTime(origin, destination);

    const { data: {
        baseFare, 
        costPerFirst5Km, 
        costPerKm, 
        costPerMin 
    } } = await getPrices(vehicle);

    let distanceCost;
    let durationCost;

    if(distance <= 5) {
        distanceCost = costPerFirst5Km;
    } else {
        distance = distance - 5;
        distanceCost = baseFare + costPerFirst5Km + (costPerKm * distance);
    }

    durationCost = costPerMin * duration;

    return distanceCost + durationCost;
}

// calculates the cost btn two places without subtracting the first 5km and calculating for it separately
const calculateCostForMultiple = async (origin, destination, vehicle) => {
    let { distance, duration } = await getDistanceAndTime(origin, destination);

    let { data: { costPerKm, costPerMin } } = await getPrices(vehicle);
    costPerKm = 0.75 * costPerKm;
    costPerMin = 0.5 * costPerMin;

    let distanceCost;
    let durationCost;

    distanceCost = costPerKm * distance;

    durationCost = costPerMin * duration;

    return distanceCost + durationCost;
}


const calculateTotal = async ({ origin, destinations, vehicle }) => {
    let total = 0;

    total += await calculateCost(origin, destinations[0], vehicle);

    for(let d of destinations) {
        const nextIndex = destinations.indexOf(d) + 1;
        const currentIndex = destinations.indexOf(d);

        if(currentIndex < (destinations.length - 1)) {
            total += await calculateCostForMultiple(d, destinations[nextIndex], vehicle);
        }
    }
    
    // calculating cost for stops (with two addresses onwards)
    if(destinations.length >= 2) {
        total += 3 * destinations.length;
    }

    return Number(Math.round(total)).toFixed(2);
}

export default calculateTotal;