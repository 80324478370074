import { GoogleMap } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { useState, useEffect } from 'react';

const center = {
    lat: 5.573536,
    lng: -0.206088
}; 

const containerStyle = {
    flex: 1
};

export default function Map({ show, setShow, setAddress }) {
    const [_address, _setAddress] = useState('Pick an address');

    useEffect(() => {
        if(_address === 'Pick an address') {
            setAddress('');
        } else {
            setAddress(_address);
        }
    }, [_address, setAddress])

    const handleClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        Geocode.setApiKey("AIzaSyAcnB8v2wzr0q1y_HsuaVP390s56_85YTo");
        Geocode.fromLatLng(lat, lng)
        .then(res => {
            const address = res.results[0].formatted_address;
            _setAddress(address);
        },
        error => {
            console.error(error);
        }
        );
    }
    

    return (
        <div className='map' style={{display: !show&& 'none'}}>
            <GoogleMap 
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
                onClick={handleClick}
            ></GoogleMap>

            <div className='map-info-con'>
                <div className='map-info row'>
                    <p>{_address}</p>
                    <button onClick={() => setShow(false)}>OK</button>
                </div>
            </div>
        </div>
    );
}
