import axios from 'axios';
import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Header from './components/Header/Header';
import TopHeader from './components/Header/TopHeader';
import BecomeAClient from './pages/BecomeAClient/BecomeAClient';
import Home from './pages/Home';
import LoginOrRegister from './pages/LoginOrRegister';
import Rider from './pages/Rider/Rider';

function App() {
  useEffect(() => {
    document.getElementById('loading-page').style.display = 'none';
  });

  axios.defaults.baseURL = 'https://ntemdeliveries.com/speedntembackend';

  return (
    <Router>
        <TopHeader />
        <Header />
        <Switch>
          <Route path='/login-or-register' component={LoginOrRegister} />
          <Route path='/client' component={BecomeAClient} />
          <Route path='/rider' component={Rider} />
          <Route path='/' component={Home} />
        </Switch>
      </Router>
  );
}

export default App;
