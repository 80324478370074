import { Check, Error } from '@material-ui/icons';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import './BecomeAClient.css';

export default function BecomeAClient() {
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'Ntem Deliveries | Become a client';
    })

    const handlePhone = (e) => {
        if(e.target.value.length <= 10) setPhone(e.target.value);
    }

    const clearFields = () => {
        setName('');
        setPhone('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(name && phone) {
            setLoading(true);
            axios.post('/client', {name, phone})
            .then(({data}) => {
                clearFields();
                setLoading(false);
                setError('');
                setSuccess(data);
            })
            .catch(err => {
                setLoading(false);
                setSuccess('');
                if(err.response) {
                    setError(err.response.data);
                } else {
                    setError('network too slow or inactive');
                }
            })
        }

    }

    return (
        <div className='center container'>
            <form className='client' onSubmit={handleSubmit}>
                <p className='small-info'>Please leave us your contact and we will get back to you shortly</p>
                <div>
                    <p><b>Name</b></p>
                    <input 
                        value={name}
                        type='text'
                        onChange={e => setName(e.target.value)}
                        placeholder='Your name'
                        className='normal-input'
                    />
                </div>
                <div className='phone'>
                    <p><b>Phone</b></p>
                    <div className='input-con'>
                        <span>+233</span>
                        <input 
                            value={phone}
                            type='tel'
                            onChange={handlePhone}
                            placeholder='Your phone number'
                        />
                    </div>
                </div>

                {
                    success&& <div className='success'><Check /> { success }</div>
                }

                {
                    error&& <div className='error'><Error /> { error }</div>
                }
                <button>
                    {
                        loading?
                        <Loader 
                            type='ThreeDots'
                            color='white'
                            height={10}
                            width={30}/> :
                        'Submit'
                    }
                </button>
            </form>
        </div>
    )
}