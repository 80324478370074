import { Error } from '@material-ui/icons';
import { useState } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

export default function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [type, setType] = useState('individual');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [,setCookie] = useCookies();
    const history = useHistory();

    const handleSubmit = (e) => {  
        e.preventDefault();          
        setLoading(true);
        axios.post('/register', { name, email, phone, type, password })
        .then(({ data }) => {
            setCookie('ntemAccess', data.token, { 
                path: '/',
                maxAge: 10000000*3 // expires in approximately a year
            });

            // if there's a channel property then the user is a rider
            if(data.channel) {
                setCookie('channel', data.channel, { 
                    path: '/',
                    maxAge: 10000000*3 // expires in approximately a year
                });
                history.push('/rider')
            } else {
                history.push('/');
            }

            setLoading(false);
        })
        .catch((err) => {
            if(err.response) {
                setError(err.response.data);
            } else {
                setError('network too slow or inactive');
            }
            setLoading(false);
        })
    }

    return (
        <form className='register' method='POST' onSubmit={handleSubmit}>

            <div className='con'>
                <div>Name</div>
                <input 
                    className='normal-input'
                    type='text'
                    placeholder='John Doe'
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </div>

            <div className='con'>
                <div>Email</div>
                <input 
                    className='normal-input'
                    type='email'
                    placeholder='kwame@example.com'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </div>

            <div className='row flex-start'>
            <div className='con phone'>
                <div>Phone</div>
                <input 
                    className='normal-input'
                    type='tel'
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                />
            </div>

                <div className='con'>
                    <div>Type of account</div>
                    <select value={type} onChange={e => setType(e.target.value)}>
                        <option value='individual'>Individual</option>
                        <option value='business'>Business</option>
                        <option value='rider'>Rider</option>
                    </select>
                </div>
            </div>

            <div className='con'>
                <div>Password</div>
                <input 
                    className='normal-input'
                    type='password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </div>

            {
                error&& <div className='error'><Error /> { error }</div>
            }
            
            <button type='submit'>
                {
                    loading? 
                    <Loader
                        type='ThreeDots'
                        color='white'
                        height={10}
                        width={30}/> :
                    'Register'
                }
            </button>

            <p className='small-info'>Already have an account? Click on the Login tab to login.</p>
        </form>
    )
}
