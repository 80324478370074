import { useEffect, useState } from 'react';
import './Tracker';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import getLatLng from '../../../utils/getLatLng';
import io from 'socket.io-client';

const ENDPOINT = 'https://ntem-backend.herokuapp.com';
let socket;

const Tracker = ({ data, rider }) => {
    const [directions, setDirections] = useState(null);
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    
    useEffect(() => {
        socket = io(ENDPOINT);

        socket.emit('join', { channel: rider.channel });

        socket.on('location', ({ location }) => {
            setLat(location.lat);
            setLng(location.lng);
        });

    }, [])


    const updateDirections = async () => {
        const directionsService = new window.google.maps.DirectionsService();

        const origin = await getLatLng(data.from.address);
        const destination = await getLatLng(data.to[data.to.length -1].address);

        // we don't need the last address in the waypoints
        data.to.pop();

        const waypoints = data.to.map(({ address }) => {
            return {
                location: address
            }
        })

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
                waypoints,
            
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
   }

   useEffect(() => {
    updateDirections();
   }, []);

    return (
        <div className='tracker'>
            <GoogleMap 
                mapContainerStyle={{
                    flex: 1, 
                    height: 500, 
                    marginBottom: 30,
                    borderRadius: 7,
                    boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.06)'
                }}
                zoom={13}
                center={{lat, lng}}
            >
                <DirectionsRenderer directions={directions}/>
                {
                    rider&&
                    <Marker position={{lat, lng}} animation={window.google.maps.Animation.BOUNCE}/>
                }
            </GoogleMap>
        </div>
    )
}

export default Tracker;