import { Error, Refresh, SearchRounded } from '@material-ui/icons';
import { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';


import './TrackDelivery.css';
import TrackingItem from './TrackingItem';
import Tracker from './Tracker/Tracker';

export default function TrackDelivery() {
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [delivery, setDelivery] = useState();
    const [rider, setRider] = useState('');

    useEffect(() => {
        document.title = 'Ntem Deliveries | Track Order';
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        if(id) {
            setLoading(true);
            axios(`/track-delivery/${id}`)
            .then(({ data }) => {
                setDelivery(data.delivery);
                setRider(data.rider);
                setError('');
                setLoading(false);
            })
            .catch((err) => {
                if(err.response) {
                    setError(err.response.data);
                } else {
                    setError('network too slow or inactive');
                }
                setLoading(false);
            })
        }
    }

    return (
        <div className='track'>
            <form className='input-con' onSubmit={handleSubmit}>
                <input 
                    type='text'
                    value={id}
                    onChange={e => setId(e.target.value)}
                    placeholder='Insert your tracking id here'
                />

                <button className='search'>
                    {
                        loading?
                        <Loader
                            type='ThreeDots'
                            color='white'
                            height={13}
                            width={25}/> :
                        delivery? <Refresh />:
                        <SearchRounded />
                    }
                </button>
            </form>

            {
                delivery&& delivery.status === 'pending'&&
                <p className='small-info'>We will assign your order to a rider as soon as possible</p>
            }

            {
                error&& <div className='error'><Error /> { error }</div>
            }

            {
                delivery&& 
                <div>
                    <Tracker data={delivery} rider={rider} />
                    <TrackingItem data={delivery} rider={rider}/>
                </div>
            }

        </div>
    )
}
