const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                if (position) {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    resolve({ lat, lng });
                }
            },
            err => reject(err.message)
            );
        }
    })
}

export default getCurrentLocation;